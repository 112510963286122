import React from "react"
import { useInView } from "react-intersection-observer"

const ScrollableSection = ({ children, classNames }) => {
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    })
    const addedClass = inView ? `is-inview` : ``
    return (
        <section
            className={`scroll-section ${classNames} ${addedClass}`}
            ref={ref}
        >
            {children}
        </section>
    )
}

export default ScrollableSection
